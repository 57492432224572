<template>
  <div class="box">
    <div class="tabs">
      <div
        v-for="(item, index) in tabs"
        :key="index"
        :class="{ current: active === index }"
        class="tab"
        @click="switchTab(index, item)"
      >
        {{ item.title }}
      </div>
    </div>

    <List
      v-model="loading"
      :finished="finished"
      class="adjust_height"
      finished-text="到底啦～"
      @load="onLoad"
    >
      <div v-for="(item, idx) in list" :key="idx" class="content">
        <div class="card" @click="goDetail(item)">
          <CommonTitle :title="item.product[0].merchantName">
            <template v-slot:orderStatus>
              <div class="pay_status">
                <!-- :class="{black:orderStatus.checkOrderStatus(item)=='已取消', green:orderStatus.checkOrderStatus(item)=='已完成' }" -->
                <span :class="changColor(item)">
                  {{ orderStatus.checkOrderStatus(item) }}
                </span>
              </div>
            </template>
          </CommonTitle>
          <div class="drug_list">
            <DrugItem
              v-for="(drugData, index) in item.product"
              :key="index"
              :data="drugData"
            >
              <template v-slot:numOrCount>
                <p class="num">x{{ drugData.count }}</p>
              </template>
            </DrugItem>
            <Cost :dis-cos="item.freight" :sum-money="item.amount" />
          </div>
        </div>
        <div />
        <div
          v-if="
            (comCancelBtn(item) && item.orderMixStatus != 'DRUG_PAID') ||
            comTrackBtn(item) ||
            comPayBtn(item)
          "
          class="btns"
        >
          <button
            v-show="comCancelBtn(item) && item.orderMixStatus != 'DRUG_PAID'"
            class="cancel_btn"
            @click="cancel(item)"
          >
            取消订单
          </button>
          <button
            v-show="comTrackBtn(item)"
            class="cancel_btn"
            @click="goTrack(item)"
          >
            查看物流
          </button>
          <button
            v-show="comPayBtn(item)"
            class="pay_btn"
            @click="payMondey(item)"
          >
            立即支付
          </button>
        </div>
      </div>
    </List>

    <Dialog
      v-model="showCancelDialog"
      :confirm-button-color="'#F76D32'"
      :cancel-button-color="'#62636C'"
      confirm-button-text="确定"
      title="确认取消?"
      width="280px"
      show-cancel-button
      @confirm="handleConfirm"
      @cancel="handleCancel"
    >
      <!-- <div class="content">
                <Field
                    v-model.trim="cancelReason"
                    rows="1"
                    autosize
                    type="textarea"
                    placeholder="请输入取消原因" />
            </div> -->
    </Dialog>
    <div v-if="isJumpPaiAnKang" class="icon_box" @click="toAgreement">
      关于思派
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk';
import axios from 'axios';
import {
  List,
  Dialog,
  //  Field,Toast,
} from 'vant';
import CommonTitle from '@/components/order/CommonTitle';
import DrugItem from '@/components/order/drug-item';
import Cost from '@/components/order/cost';
import Configs from '@/config';
// import Cashier from '@med/cashier';
import api from '@/api';
import {
  checkOrderStatus,
  showCancelBtn,
  showPayBtn,
  showTrackBtn,
  handleAfter401,
} from '@/utils';

export default {
  name: 'OrderList',
  components: {
    List,
    CommonTitle,
    DrugItem,
    Cost,
    Dialog: Dialog.Component,
    // Field,Toast,
  },
  data () {
    return {
      cancelArr: [],
      tabs: [
        { key: 'ALL', title: '全部' },
        { key: 'UNPAID', title: '待付款' },
        { key: 'DELIVERY_PENDING', title: '待发货' },
        { key: 'DONE', title: '已完成' },
      ],
      active: 0,
      qryType: 'ALL',
      pageSize: 10,
      page: 1,
      totalPage: 0,

      loading: false,
      finished: false,
      list: [],
      mainOrderNo: '',
      orderStatus: {
        checkOrderStatus,
      },
      showCancelDialog: false,
      cancelReason: '用户自行取消订单',
      checked: true,
      count: 0,
      // getListCount: 0,
    };
  },
  computed: {
    comPayBtn () {
      return (item) => showPayBtn(item);
    },
    comCancelBtn () {
      return (item) => showCancelBtn(item);
    },
    comTrackBtn () {
      return (item) => showTrackBtn(item);
    },
    changColor () {
      return (item) => {
        if (
          item.orderMixStatus === 'DRUG_CANCEL'
          || item.orderMixStatus === 'DRUG_REFUNDED'
        ) {
          return 'black';
        }

        if (
          (item.orderMixStatus === 'DRUG_DONE'
            || item.orderMixStatus === 'DRUG_PAID')
          && item.logisticsStatus === 'SIGNED'
        ) {
          return 'green';
        }
      };
    },
    isJumpPaiAnKang () {
      return window.__wxjs_environment === 'miniprogram' && localStorage.getItem('jumpMpPay') === 'yes';
    }
  },
  created () {
    this.list = [];
    this.totalPage = 0;
    this.getList();
  },
  // activated () {
  //   if (!this.$route.meta.isBack) {
  //     this.list = [];
  //     this.page = 1;
  //     this.getList();
  //   }
  //   this.$route.meta.isBack = false;
  // },
  // beforeRouteEnter (to, from, next) {
  //   const isCancal = sessionStorage.getItem('isCancal')
  //   if ((from.name === "detail" && isCancal === 'false') || from.name === 'log') {
  //     to.meta.isBack = true;
  //   } else {
  //     to.meta.isBack = false;
  //   }
  //   next();
  // },
  methods: {
    // 跳转到派安康的协议列表页面
    toAgreement () {
      wx.miniProgram.navigateTo({
        url: `/pages/agreements/list/index`,
      });
    },
    switchTab (index, item) {
      this.active = index;
      this.qryType = item.key;
      this.loading = true; // 下拉加载中
      this.finished = false; // 下拉结束
      this.page = 1;
      this.list = [];
      if (this.cancelArr) {
        this.cancelArr.forEach((cancel, idx) => {
          cancel();
          // 取消网络请求
          delete this.cancelArr[idx];
        });
      }
      this.getList();
    },
    createCancleArr (that) {
      // 收集请求集合
      return new axios.CancelToken((c) => {
        // 数组存储一个cancel函数用于取消本次的网络请求
        that.cancelArr.push(c);
      });
    },
    async getList () {
      // this.getListCount += 1;
      const param = {
        pageSize: this.pageSize,
        page: this.page,
        qryType: this.qryType,
        // count: this.getListCount,
      };
      await api
        .getOrderList(param, this.createCancleArr(this))
        // .getOrderList(param)
        .then((res) => {
          // this.getListCount = 0;
          const { list = [], totalPage = 0 } = res.data;
          list.forEach((item) => {
            if (Number(item.origin) <= 5) {
              item.freight = 0;
            } else {
              item.freight /= 100;
            }
            item.amount /= 100;
            item.product.forEach((p) => {
              p.salePrice = Number(p.salePrice) / 100;
            });
          });
          this.list = this.list.concat(list);
          this.loading = false;
          this.totalPage = totalPage;
          if (this.page >= this.totalPage) {
            this.finished = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onLoad () {
      if (this.totalPage && this.page < this.totalPage) {
        this.page += 1;
        this.getList();
      }
    },
    cancel (item) {
      this.showCancelDialog = true;
      this.mainOrderNo = item.mainOrderNo;
    },
    handleConfirm () {
      this.count += 1;
      // if (!this.cancelReason) {
      //     return Toast.fail('请填写取消原因');
      // }
      const param = {
        cancelReason: this.cancelReason,
        mainOrderNo: this.mainOrderNo,
        count: this.count,
      };
      api.getOrderCancel(param).then((res) => {
        this.count = 0;
        this.showCancelDialog = false;
        // this.cancelReason = '';
        this.page = 1;
        this.list = [];
        this.getList();
        console.log(res, '--cancel');
      });
    },
    handleCancel () {
      this.showCancelDialog = false;
    },
    async payMondey (item) {
      const param = {
        mainOrderNo: item.mainOrderNo,
        payAmount: item.amount,
        merchantCode: item.product[0].drugstoreCode,
        shareCode:
          window.localStorage.getItem('shareCode') || undefined,
        homeUrl: `${window.location.origin}/pysc/home`,
        detailUrl: `${window.location.origin}/pysc/detail/${item.mainOrderNo}`,
      };
      if (window.__wxjs_environment === 'miniprogram') {
        // 是小程序环境
        param.clientSide = 'applets';
        //派安康小程序
        const channelCode = localStorage.getItem('channelCode')
        if (channelCode === "PAK") { // 派安康来源
          param.originStatus = 'PAK';
        }
      }
      if (param.shareCode) {
        param.qrCodeData = Configs.qrCodeData;
      }

      const ua = window.navigator.userAgent;
      if (ua.includes && ua.includes('wxwork')) {
        param.clientSide = 'wxwork';
      }
      if (window.__wxjs_environment === 'miniprogram' && localStorage.getItem('jumpMpPay') && localStorage.getItem('jumpMpPay') === 'yes') {
        // 微信环境 && 跳转小程序标识是 yes
        this.toMiniProgram(param.merchantCode, item)
      } else {
        this.normalH5Pay(param)
      }

    },
    // h5正常支付流程
    normalH5Pay (param) {
      api.getCreatePay(param).then((res) => {
        // http://pay.medbanks-test.com/#/?orderCacheId=xxx

        if (res.data.code === '0') {
          const orderCacheId = res.data.data;
          window.location.href = `${Configs.payUrl}?orderCacheId=${orderCacheId}`;
          localStorage.removeItem('shareCode');
        } else if (res.data.code === '21912') {
          this.count = 0;
          // 腾讯来源的已经下过单了
          Dialog.alert({
            message: '思派云药房需要您授权登录',
            confirmButtonText: '去登录',
          }).then(() => {
            api.logout({
              token: localStorage.getItem('authentication'),
            }).then((logoutRes) => {
              if (logoutRes.data.code === '0') {
                localStorage.removeItem('authentication');
                localStorage.removeItem('userId');
                localStorage.removeItem('phone');
                handleAfter401();
              }
            });
          });
        } else {
          this.page = 1;
          this.list = [];
          this.getList();
        }
      });
    },
    // 跳转到微信小程序
    toMiniProgram (merchantCode, data) {
      const { amount, submitDate, mainOrderNo, product } = data
      const query = {
        merchantCode,
        amount,
        dateCreated: submitDate,
        mainOrderNo,
        product,
        token: localStorage.getItem('authentication')
      }
      wx.miniProgram.navigateTo({
        url: `/pages/payment/pay/index?query=${JSON.stringify(query)}`,
      });
    },
    goDetail (item) {
      this.$router.push({
        name: 'detail',
        params: {
          mainOrderNo: item.mainOrderNo,
        },
      });
    },
    goTrack (item) {
      this.$router.push({
        name: 'log',
        params: {
          id: item.mainOrderNo,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.adjust_height {
  // height: calc(100vh - 44px);
  .content {
    margin-bottom: 8px !important;
    background: #fff !important;
  }
}
.box {
  width: 100%;
  // background: #eee;
  .icon_box {
    position: fixed;
    right: 10px;
    bottom: 104px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
  }
  .tabs {
    height: 42px;
    background: #fff;
    box-shadow: 0 0 8px 0 rgba(60, 107, 240, 0.1);
    margin-bottom: 1px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 49px;
  }
  .tab {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #62636c;
    line-height: 20px;
    padding: 9px 0 13px;
    cursor: pointer;
  }
  .current {
    font-weight: 500;
    color: #4486fb;
    border-bottom: 3px solid #4486fb;
    height: 17px;
  }
  .content {
    margin-bottom: 8px !important;
    background: #fff !important;
    .card {
      background: #fff;
      .black {
        color: #000;
      }
      .green {
        color: #20c0b4;
      }
      .pay_status {
        font-size: 14px;
        font-weight: 500;
        color: #f36218;
      }
      .drug_list {
        padding: 4px 18px 0;
        .num {
          height: 19px;
          font-size: 14px;
          font-weight: 500;
          color: #666;
          line-height: 19px;
        }
      }
    }
    .btns {
      height: 50px;
      line-height: 50px;
      text-align: right;
      padding-right: 18px;
      .cancel_btn,
      .pay_btn {
        width: 80px;
        height: 30px;
        padding: 5px 0;
        border-radius: 15px;
        border: 1px solid #f36218;
        font-weight: 500;
        font-size: 14px;
        margin-left: 8px;
      }
      .cancel_btn {
        font-size: 14px;
        font-weight: 500;
        color: #f36218;
        background: #fff;
      }
      .pay_btn {
        color: #fff;
        background: #f36218;
      }
    }
  }
  /deep/ .van-list__finished-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 0;
    width: auto;
    font-size: 11px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #babfc9;
    line-height: 16px;

    &::before,
    &::after {
      content: '';
      display: inline-block;
      margin: 0 8px;
      width: 40px;
      height: 1px;
      background: #dcdfe4;
      color: red;
    }
  }
}
</style>
